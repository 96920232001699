@import './_variables';

nav {
    display: flex;
    justify-content: space-between;
    padding: 40px 50px 0 50px;
    background: $bg-primary;

    .nav-items {
        ul {
            display: flex;
            list-style-type: none;

            a {
                text-decoration: none;
                margin-left: 30px;
                color: $primary-text-clr;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                letter-spacing: 0.02em;
                
                
                &:hover {
                   
                    text-decoration: underline;
                    text-underline-offset: 2px;
                   
                }
                &::after{
                    transition: all 5s ease-Out; 
                    
                }
            }
        }
    }
}

@media  (max-width:767px) {
    nav{
        padding: 40px 30px 0 30px;

        .logo{
            img{
                height: 20px;
            }
        }
        .nav-items {
            display: none;
        }
    }
}
@media  (min-width:768px) and (max-width: 1060px){
    nav{
        padding: 40px 60px 0 60px;
    }
}