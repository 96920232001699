// Background
$primary-clr: #000;
$background-clr-1: #ffffff;
$background-clr-2: #fafafa;
$bg-primary:#fff;
$bg-secondary:#f4f4f4;

//font colors
$primary-text-clr: #000;
$text-primary: #ffffff;
$text-primary-2: #000000;
$text-secondary: #555555;
$text-secondary-2: #767676;
$secondary-text-clr-2: #747474;

//line spacing
$line-height-body-copy: 32px;
$line-height-body-copy-mobile: 28px;
$line-height-hero-title: 110px;
$line-height-hero-title-mobile: 55px;



//link
$link-hover-clr: rgba(0, 0, 0, 0.518);

//grey
$grey-1: #f2f2f2;
