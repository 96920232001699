@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');


@import './styles/variables';
@import './styles/mixing';
@import 'animate.css';


*,
body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    
   
}
body{
    // background: #FBFBFD;
}
h2 {
  
    font-weight: 600;
    font-size: 70px;
    line-height: 70px;
}
h3 {
    
    font-weight: 600;
    font-size: 55px;
    line-height: 45px;
    // letter-spacing: -1px;
}


.description {
    @include seconday-fontfam(20px, 30px, 400);
    color: $text-secondary
}

.text-bold{
  font-weight: 500; 
}
.text-line-through{
    text-decoration-line: line-through;
    color: rgb(164, 164, 164);
}

::selection {
    color: rgb(255, 255, 255);
    // background: rgb(0, 0, 0);
    background: rgb(0, 130, 236);
  }