@import './variables';
@import './_mixing';

.container {
    // height: 100vh;
    // background: #FBFBFD;


    .hero {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 200px;
        background-color: $bg-primary;
        // height: calc(100vh - 72.5px);
        
        position: relative;

        .title {
            // font-family: 'Poppins';
            font-weight: bold;
            font-size: 90px;
            line-height: $line-height-hero-title;
            max-width: 1200px;
            text-align: center;
            margin-bottom: 30px;
            letter-spacing: -1.3px;
        }

        .body {
            @include seconday-fontfam(20px, $line-height-body-copy, 400);
            text-align: center;
            max-width: 900px;
            margin-bottom: 30px;
            color: $text-secondary;
        }

      
        .cta {
            color: $primary-text-clr;
            text-decoration: none;
            font-weight: 300;
            font-size: 16px;
            line-height: 18px;
            padding: 18px 32px;
            border-radius: 60px;
            background: $primary-clr;
            color: $text-primary;
            border: 2px solid black;
            letter-spacing: -.2px;

            &:hover {
                // color: rgb(122, 122, 122)
                transition: all .26s ease-Out;
                background: white;
                border: 2px solid black;
                color:black;
                // font-weight:400;
            }

    
        }

        .hero-img {
            // position: absolute;
            // bottom: 0;
            margin-top: 180px;
            max-width: 990px;
        }
    }
}


//media queries
@media  (max-width:767px) {
    .container {
        padding: 0 30px;

        .hero {
            display: block;
            padding-top: 100px;

            .title {
                font-size: 60px;
                line-height: $line-height-hero-title-mobile;
                // max-width: 400px;
                min-width: 312px;
                text-align: left;
                margin-bottom: 30px;
                // color:red;
                text-align: left;
            }

            .body {

                font-size: 16px;
                line-height: $line-height-body-copy-mobile;
                text-align: left;
                // max-width: 400px;
                min-width: 312px;
                margin-bottom: 30px;
            }

            .cta {
                display: inline-block;
                font-size: 16px;
                padding: 15px 30px;
                border-radius: 60px;
                // min-width: 165px;

                &:hover {
                    color: rgb(122, 122, 122)
                }
            }

            .hero-img {
                width: 100%;
                margin-top: 180px;
                max-width: 700px;
                ;
            }
        }
    }

}
@media  (min-width:768px) and (max-width: 1060px){
    .container {
        padding: 0 30px;

        .hero {


            .title {
                font-size: 70px;
                line-height: 70px;
                min-width: none;
            }

            .body {

                font-size: 18px;
                max-width: 800px;
            }

            .cta {
                display: inline-block;
                font-size: 16px;
                padding: 15px 30px;
                border-radius: 60px;
                // min-width: 165px;

                &:hover {
                    color: rgb(122, 122, 122)
                }
            }

            .hero-img {
                width: 100%;
                margin-top: 180px;
                max-width: 700px;
                ;
            }
        }
    }

}