@import './_mixing';
@import './_variables';

footer {
    padding: 150px 60px 30px 60px;
    border-top: 1px solid $grey-1;
    width: 100%;
    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;

        p {
            @include seconday-fontfam(16px, 28px, 400);
            text-transform: uppercase;
            color: $text-secondary-2;

        }

        h4 {
            font-size: 48px;
            font-weight: 600;
            text-transform: uppercase;
            margin: 20px 0;
            letter-spacing: -0.5px;
            line-height: 48px;
            text-align: center;
        }

        :last-child {
            @include seconday-fontfam(24px, 38px, 400);
            text-transform: lowercase;
            letter-spacing: 1px;
            color: $text-primary-2;
        }
    }

    .bottom{
        display: flex;
        justify-content: space-between;

        .copy-right{
            @include seconday-fontfam(14px, 24px, 400); 
        }

        .social-media{
            display: flex;
            align-self: center;
            
            img{
                padding: 0 10px;
                cursor: pointer;
            }

            p{
                @include seconday-fontfam(12px, 24px, 400);
                text-transform: uppercase;
                color: $text-secondary-2;
            }
        }
    }
}

@media (max-width:767px) {
    footer {
        padding: 100px 30px 20px 30px;
    
        .container {

            align-items: center;
            margin-bottom: 80px;
    
            p {
                @include seconday-fontfam(14px, 26px, 400);
            }
    
            h4 {
                font-size: 32px;
                font-weight: 600;
                text-align: center;
                margin: 20px 0;
                letter-spacing: -0.5px;
                line-height: 32px;
            }
    
            :last-child {
                @include seconday-fontfam(16px, 28px, 400);
                text-transform: lowercase;
                letter-spacing: 1px;
                color: $text-primary-2;
            }
        }
    
        .bottom{
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;
    
            .copy-right{
                @include seconday-fontfam(12px, 24px, 400); 
            }
    
            .social-media{
                display: flex;
                align-self: center;
                order: -1;
                flex-direction: column;
                margin-bottom: 60px;
                
                img{
                    padding: 0 10px;
                    cursor: pointer;
    
                }
    
                p{
                    @include seconday-fontfam(12px, 24px, 400);
                    text-transform: uppercase;
                    color: $text-secondary-2;
                    text-align: center;
                    margin-bottom: 10px;
                }
            }
        }
    }
}