@import './_mixing';

.container-case-study {
    display: flex;
    align-items: center;
    flex-direction: column;

    .heading {
        max-width: 980px;
        margin-top: 80px;
        margin-bottom: 80px;

        .project-name {
            margin-bottom: 20px;
            text-transform: uppercase;
            letter-spacing: 2px;
            font-size: 18px;
            font-weight: 500;
        }

        .project-short-description {
            @include seconday-fontfam(64px, 62px, 700);
            letter-spacing: -1.2px;
        }
    }

    .cover-img {
        width: 100%;
        object-fit: cover;
        // max-height: 780px;
        margin-bottom: 80px;
    }

    .container-980 {
        max-width: 980px;

        .properties {
            display: grid;
            grid-template-columns: 215px 1fr;
            margin-bottom: 50px;
            grid-column-gap: 20px;

            .property {
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
            }

            .value {
                @include seconday-fontfam(18px, 30px, 400);
                max-width: 735px;
                width: fit-content;

                .highlight-large {
                    @include seconday-fontfam(20px, 32px, 600);

                    display: block;
                    margin-bottom: 10px;
                }
                .highlight-bold {
                    @include seconday-fontfam(18px, 30px, 600);
                }
            }
            .col-2{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-column-gap: 30px;
            }
           
            .image-value {
                max-width: 535px;
                // max-width: 100%;
                max-height: 100%;
            }
        }
    }

    .divider {
        border-bottom: 1px solid rgb(212, 212, 212);
        margin-top: 60px;
        margin-bottom: 60px;
    }

    .image-980 {
        display: block;
        max-width: 980px;
        // border-radius: 15px; 
    }

    .image-full-width {
        display: block;
        width: auto;
        height: 100vh;
        object-fit: cover;
    }

    .image-auto-width {
        // min-width:500px;
        align-items: right;
        // width: 50%;
    }

}
@media (max-width:767px) {

    .container-case-study {

        .heading {
            margin-top: 60px;
            margin-bottom: 40px;
        
            padding: 0 30px;
            .project-name {
                @include seconday-fontfam(16px, 24px, 500);
                margin-bottom: 20px;
                text-transform: uppercase;
                letter-spacing: 1px;
            }
    
            .project-short-description {
                font-size: 40px;
                line-height: 40px;
            }
        }
    
        .cover-img {
            width: auto;
            object-fit: cover;
            max-height: 500px;
            margin-bottom: 60px;
        }

        .container-980 {
            max-width: 100%;
            padding: 0 30px;

            .properties {
               display: block;
               margin-bottom: 40px;

                .property {
                    min-width: 100%;
                    margin-right: 20px;
    
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 10px;
                }
    
                .value {
                    @include seconday-fontfam(16px, 24px, 400);
                    max-width: 100%;
                    width: fit-content;
    
                    .highlight-large {
                        @include seconday-fontfam(18px, 24px, 600);
    
                        display: block;
                        margin-bottom: 10px;
                    }
                }
    
                .image-value {
                    
                    // max-width: 535px;
                    max-width: 100%;
                    max-height: 100%;
                }
                .col-2{
                    display: flex;
                    flex-direction: column;
                    
                    .value{
                        max-width: 80%;
                        margin-bottom: 30px;
                    }
                }
            }

           
        }
        .divider {
            margin-top: 40px;
            margin-bottom: 40px;
        }
    }
    }

    @media  (min-width:768px) and (max-width: 1060px){

        .heading {
            padding: 0 60px;
        }
        .container-980 {
            // max-width: 800px;
            padding: 0 60px;
    
            .properties {
                display: grid;
                grid-template-columns: 215px 1fr;
    
                .col-2{
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    grid-column-gap: 30px;
                }
            }
        } 
    }
