.about {
    margin-top: 200px;
    max-width: 980px;
    margin-left: auto;
    margin-right: auto;

    .welcome {
        font-weight: 700;
        font-size: 150px;
        line-height: 150px;
        letter-spacing: -1.5px;
        margin-bottom: 40px;
    }

    img {
        display: block;
        border-radius: 20px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 150px;
    }
}