@import './variables';


.works {
    padding-top: 150px;
    // background-color: $bg-secondary;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;


    h3 {
        margin-bottom: 20px;
        color: $primary-text-clr;
    }

    p {
        color: $text-secondary;
        margin-bottom: 60px;
    }

    .selected-work-card {
        width: 940px;
        height: 353px;
        background-color: $bg-primary;
        border-radius: 20px;
        padding: 50px;
        margin-bottom: 40px;

        .project-name {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            /* identical to box height, or 144% */

            letter-spacing: 0.15em;
            text-transform: uppercase;
            color: $text-secondary;
        }

        .title {
            margin-top: 20px;
            width: 420px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 36px;
            line-height: 46px;
            /* or 128% */

            letter-spacing: -0.025em;
        }

        .link-project {
            margin-top: 50px;
        }
    }

    #scroll-container {
        position: absolute;
        width: 100%;
        height: 100vh;
        z-index: -1;
    }

    section {
        position: absolute;
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
        background: #202020;
        color: #FFF;
        font-family: Montserrat, sans-serif;
        font-weight: 700;
        font-size: 2rem;
        text-transform: uppercase;
        letter-spacing: 0.7rem;
    }

    section:nth-child(even) {
        background: #E8E8E8;
        color: #181818;
    }
}

.container-work {
//    max-width: 1600px;
    padding-top: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // background: rgb(255, 255, 255);
    background: #FBFBFD;
    // margin-bottom: 60px;
    h3{
        font-size: 50px;
        margin-bottom: 20px;
    }
    p{
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 60px;
    }

    .cards {
        display: grid;
        grid-template-columns: 30% 40% 30%;
        max-width: 1920px;
        width: 100%;
        grid-template-rows: 400px 400px;
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        grid-template-areas:
            "c1 c2 c3"
            "c4 c2 c5";


        .card-1 {
            grid-area: c1;
            border-radius: 20px;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%; 
               
            }

        }

        .card-2 {
            grid-area: c2;
            
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
               
            }
        }

        .card-3 {
            grid-area: c3;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
               

            }

        }

        .card-4 {
            grid-area: c4;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
               
            }

        }

        .card-5 {
            grid-area: c5;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}

@media (max-width:767px) {
    .container-work {
    .cards {
        display: flex;
       flex-direction: column;


        .card-1 {
            grid-area: c1;
            border-radius: 20px;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%; 
               
            }

        }

        .card-2 {
            grid-area: c2;
            
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
               
            }
        }

        .card-3 {
            grid-area: c3;

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
               

            }

        }

        .card-4 {
            grid-area: c4;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
               
            }

        }

        .card-5 {
            grid-area: c5;
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
    }
}
}

@media  (min-width:768px) and (max-width: 1060px){
    .container-work {
            padding-top: 150px;
           
            h3{
                font-size: 50px;
                margin-bottom: 20px;
            }
            p{
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 60px;
            }
        
            .cards {
                display: grid;
                grid-template-columns: 1fr 1fr;
                width: 100%;
                grid-template-rows: 500px 500px 500px;
                grid-column-gap: 10px;
                grid-row-gap: 10px;
                grid-template-areas:
                    "c1 c2 "
                    "c3 c2"
                    "c4  c5";
            }
        }        
}
@media  (min-width:1061px)and (max-width: 1280px){
    .container-work {
           
            .cards {
               
                grid-template-rows: repeat(3,280px);
                
            }
        }        
}
@media  (min-width:1920px){
    .container-work {
            padding-top: 150px;
           
            h3{
                font-size: 50px;
                margin-bottom: 20px;
            }
            p{
                font-size: 18px;
                line-height: 22px;
                margin-bottom: 60px;
            }
        
            .cards {
               
                grid-template-rows: repeat(3,550px);
                
            }
        }        
}